export default [
  {
    key: "AF",
    value: "Afghanistan (+93)",
  },
  {
    key: "AL",
    value: "Albania (+355)",
  },
  {
    key: "DZ",
    value: "Algeria (+213)",
  },
  {
    key: "AS",
    value: "American Samoa (+1-684)",
  },
  {
    key: "AD",
    value: "Andorra (+376)",
  },
  {
    key: "AO",
    value: "Angola (+244)",
  },
  {
    key: "AI",
    value: "Anguilla (+1-264)",
  },
  {
    key: "AQ",
    value: "Antarctica (+672)",
  },
  {
    key: "AG",
    value: "Antigua and Barbuda (+1-268)",
  },
  {
    key: "AR",
    value: "Argentina (+54)",
  },
  {
    key: "AM",
    value: "Armenia (+374)",
  },
  {
    key: "AW",
    value: "Aruba (+297)",
  },
  {
    key: "AU",
    value: "Australia (+61)",
  },
  {
    key: "AT",
    value: "Austria (+43)",
  },
  {
    key: "AZ",
    value: "Azerbaijan (+994)",
  },
  {
    key: "BS",
    value: "Bahamas (+1-242)",
  },
  {
    key: "BH",
    value: "Bahrain (+973)",
  },
  {
    key: "BD",
    value: "Bangladesh (+880)",
  },
  {
    key: "BB",
    value: "Barbados (+1-246)",
  },
  {
    key: "BY",
    value: "Belarus (+375)",
  },
  {
    key: "BE",
    value: "Belgium (+32)",
  },
  {
    key: "BZ",
    value: "Belize (+501)",
  },
  {
    key: "BJ",
    value: "Benin (+229)",
  },
  {
    key: "BM",
    value: "Bermuda (+1-441)",
  },
  {
    key: "BT",
    value: "Bhutan (+975)",
  },
  {
    key: "BO",
    value: "Bolivia (+591)",
  },
  {
    key: "BA",
    value: "Bosnia and Herzegovina (+387)",
  },
  {
    key: "BW",
    value: "Botswana (+267)",
  },
  {
    key: "BR",
    value: "Brazil (+55)",
  },
  {
    key: "IO",
    value: "British Indian Ocean Territory (+246)",
  },
  {
    key: "VG",
    value: "British Virgin Islands (+1-284)",
  },
  {
    key: "BN",
    value: "Brunei (+673)",
  },
  {
    key: "BG",
    value: "Bulgaria (+359)",
  },
  {
    key: "BF",
    value: "Burkina Faso (+226)",
  },
  {
    key: "BI",
    value: "Burundi (+257)",
  },
  {
    key: "KH",
    value: "Cambodia (+855)",
  },
  {
    key: "CM",
    value: "Cameroon (+237)",
  },
  {
    key: "CA",
    value: "Canada (+1)",
  },
  {
    key: "CV",
    value: "Cape Verde (+238)",
  },
  {
    key: "KY",
    value: "Cayman Islands (+1-345)",
  },
  {
    key: "CF",
    value: "Central African Republic (+236)",
  },
  {
    key: "TD",
    value: "Chad (+235)",
  },
  {
    key: "CL",
    value: "Chile (+56)",
  },
  {
    key: "CN",
    value: "China (+86)",
  },
  {
    key: "CX",
    value: "Christmas Island (+61)",
  },
  {
    key: "CC",
    value: "Cocos Islands (+61)",
  },
  {
    key: "CO",
    value: "Colombia (+57)",
  },
  {
    key: "KM",
    value: "Comoros (+269)",
  },
  {
    key: "CK",
    value: "Cook Islands (+682)",
  },
  {
    key: "CR",
    value: "Costa Rica (+506)",
  },
  {
    key: "HR",
    value: "Croatia (+385)",
  },
  {
    key: "CU",
    value: "Cuba (+53)",
  },
  {
    key: "CW",
    value: "Curacao (+599)",
  },
  {
    key: "CY",
    value: "Cyprus (+357)",
  },
  {
    key: "CZ",
    value: "Czech Republic (+420)",
  },
  {
    key: "CD",
    value: "Democratic Republic of the Congo (+243)",
  },
  {
    key: "DK",
    value: "Denmark (+45)",
  },
  {
    key: "DJ",
    value: "Djibouti (+253)",
  },
  {
    key: "DM",
    value: "Dominica (+1-767)",
  },
  {
    key: "DO",
    value: "Dominican Republic (+1-809, 1-829, 1-849)",
  },
  {
    key: "TL",
    value: "East Timor (+670)",
  },
  {
    key: "EC",
    value: "Ecuador (+593)",
  },
  {
    key: "EG",
    value: "Egypt (+20)",
  },
  {
    key: "SV",
    value: "El Salvador (+503)",
  },
  {
    key: "GQ",
    value: "Equatorial Guinea (+240)",
  },
  {
    key: "ER",
    value: "Eritrea (+291)",
  },
  {
    key: "EE",
    value: "Estonia (+372)",
  },
  {
    key: "ET",
    value: "Ethiopia (+251)",
  },
  {
    key: "FK",
    value: "Falkland Islands (+500)",
  },
  {
    key: "FO",
    value: "Faroe Islands (+298)",
  },
  {
    key: "FJ",
    value: "Fiji (+679)",
  },
  {
    key: "FI",
    value: "Finland (+358)",
  },
  {
    key: "FR",
    value: "France (+33)",
  },
  {
    key: "PF",
    value: "French Polynesia (+689)",
  },
  {
    key: "GA",
    value: "Gabon (+241)",
  },
  {
    key: "GM",
    value: "Gambia (+220)",
  },
  {
    key: "GE",
    value: "Georgia (+995)",
  },
  {
    key: "DE",
    value: "Germany (+49)",
  },
  {
    key: "GH",
    value: "Ghana (+233)",
  },
  {
    key: "GI",
    value: "Gibraltar (+350)",
  },
  {
    key: "GR",
    value: "Greece (+30)",
  },
  {
    key: "GL",
    value: "Greenland (+299)",
  },
  {
    key: "GD",
    value: "Grenada (+1-473)",
  },
  {
    key: "GU",
    value: "Guam (+1-671)",
  },
  {
    key: "GT",
    value: "Guatemala (+502)",
  },
  {
    key: "GG",
    value: "Guernsey (+44-1481)",
  },
  {
    key: "GN",
    value: "Guinea (+224)",
  },
  {
    key: "GW",
    value: "Guinea-Bissau (+245)",
  },
  {
    key: "GY",
    value: "Guyana (+592)",
  },
  {
    key: "HT",
    value: "Haiti (+509)",
  },
  {
    key: "HN",
    value: "Honduras (+504)",
  },
  {
    key: "HK",
    value: "Hong Kong (+852)",
  },
  {
    key: "HU",
    value: "Hungary (+36)",
  },
  {
    key: "IS",
    value: "Iceland (+354)",
  },
  {
    key: "IN",
    value: "India (+91)",
  },
  {
    key: "ID",
    value: "Indonesia (+62)",
  },
  {
    key: "IR",
    value: "Iran (+98)",
  },
  {
    key: "IQ",
    value: "Iraq (+964)",
  },
  {
    key: "IE",
    value: "Ireland (+353)",
  },
  {
    key: "IM",
    value: "Isle of Man (+44-1624)",
  },
  {
    key: "IL",
    value: "Israel (+972)",
  },
  {
    key: "IT",
    value: "Italy (+39)",
  },
  {
    key: "CI",
    value: "Ivory Coast (+225)",
  },
  {
    key: "JM",
    value: "Jamaica (+1-876)",
  },
  {
    key: "JP",
    value: "Japan (+81)",
  },
  {
    key: "JE",
    value: "Jersey (+44-1534)",
  },
  {
    key: "JO",
    value: "Jordan (+962)",
  },
  {
    key: "KZ",
    value: "Kazakhstan (+7)",
  },
  {
    key: "KE",
    value: "Kenya (+254)",
  },
  {
    key: "KI",
    value: "Kiribati (+686)",
  },
  {
    key: "XK",
    value: "Kosovo (+383)",
  },
  {
    key: "KW",
    value: "Kuwait (+965)",
  },
  {
    key: "KG",
    value: "Kyrgyzstan (+996)",
  },
  {
    key: "LA",
    value: "Laos (+856)",
  },
  {
    key: "LV",
    value: "Latvia (+371)",
  },
  {
    key: "LB",
    value: "Lebanon (+961)",
  },
  {
    key: "LS",
    value: "Lesotho (+266)",
  },
  {
    key: "LR",
    value: "Liberia (+231)",
  },
  {
    key: "LY",
    value: "Libya (+218)",
  },
  {
    key: "LI",
    value: "Liechtenstein (+423)",
  },
  {
    key: "LT",
    value: "Lithuania (+370)",
  },
  {
    key: "LU",
    value: "Luxembourg (+352)",
  },
  {
    key: "MO",
    value: "Macau (+853)",
  },
  {
    key: "MK",
    value: "Macedonia (+389)",
  },
  {
    key: "MG",
    value: "Madagascar (+261)",
  },
  {
    key: "MW",
    value: "Malawi (+265)",
  },
  {
    key: "MY",
    value: "Malaysia (+60)",
  },
  {
    key: "MV",
    value: "Maldives (+960)",
  },
  {
    key: "ML",
    value: "Mali (+223)",
  },
  {
    key: "MT",
    value: "Malta (+356)",
  },
  {
    key: "MH",
    value: "Marshall Islands (+692)",
  },
  {
    key: "MR",
    value: "Mauritania (+222)",
  },
  {
    key: "MU",
    value: "Mauritius (+230)",
  },
  {
    key: "YT",
    value: "Mayotte (+262)",
  },
  {
    key: "MX",
    value: "Mexico (+52)",
  },
  {
    key: "FM",
    value: "Micronesia (+691)",
  },
  {
    key: "MD",
    value: "Moldova (+373)",
  },
  {
    key: "MC",
    value: "Monaco (+377)",
  },
  {
    key: "MN",
    value: "Mongolia (+976)",
  },
  {
    key: "ME",
    value: "Montenegro (+382)",
  },
  {
    key: "MS",
    value: "Montserrat (+1-664)",
  },
  {
    key: "MA",
    value: "Morocco (+212)",
  },
  {
    key: "MZ",
    value: "Mozambique (+258)",
  },
  {
    key: "MM",
    value: "Myanmar (+95)",
  },
  {
    key: "NA",
    value: "Namibia (+264)",
  },
  {
    key: "NR",
    value: "Nauru (+674)",
  },
  {
    key: "NP",
    value: "Nepal (+977)",
  },
  {
    key: "NL",
    value: "Netherlands (+31)",
  },
  {
    key: "AN",
    value: "Netherlands Antilles (+599)",
  },
  {
    key: "NC",
    value: "New Caledonia (+687)",
  },
  {
    key: "NZ",
    value: "New Zealand (+64)",
  },
  {
    key: "NI",
    value: "Nicaragua (+505)",
  },
  {
    key: "NE",
    value: "Niger (+227)",
  },
  {
    key: "NG",
    value: "Nigeria (+234)",
  },
  {
    key: "NU",
    value: "Niue (+683)",
  },
  {
    key: "KP",
    value: "North Korea (+850)",
  },
  {
    key: "MP",
    value: "Northern Mariana Islands (+1-670)",
  },
  {
    key: "NO",
    value: "Norway (+47)",
  },
  {
    key: "OM",
    value: "Oman (+968)",
  },
  {
    key: "PK",
    value: "Pakistan (+92)",
  },
  {
    key: "PW",
    value: "Palau (+680)",
  },
  {
    key: "PS",
    value: "Palestine (+970)",
  },
  {
    key: "PA",
    value: "Panama (+507)",
  },
  {
    key: "PG",
    value: "Papua New Guinea (+675)",
  },
  {
    key: "PY",
    value: "Paraguay (+595)",
  },
  {
    key: "PE",
    value: "Peru (+51)",
  },
  {
    key: "PH",
    value: "Philippines (+63)",
  },
  {
    key: "PN",
    value: "Pitcairn (+64)",
  },
  {
    key: "PL",
    value: "Poland (+48)",
  },
  {
    key: "PT",
    value: "Portugal (+351)",
  },
  {
    key: "PR",
    value: "Puerto Rico (+1-787, 1-939)",
  },
  {
    key: "QA",
    value: "Qatar (+974)",
  },
  {
    key: "CG",
    value: "Republic of the Congo (+242)",
  },
  {
    key: "RE",
    value: "Reunion (+262)",
  },
  {
    key: "RO",
    value: "Romania (+40)",
  },
  {
    key: "RU",
    value: "Russia (+7)",
  },
  {
    key: "RW",
    value: "Rwanda (+250)",
  },
  {
    key: "BL",
    value: "Saint Barthelemy (+590)",
  },
  {
    key: "SH",
    value: "Saint Helena (+290)",
  },
  {
    key: "KN",
    value: "Saint Kitts and Nevis (+1-869)",
  },
  {
    key: "LC",
    value: "Saint Lucia (+1-758)",
  },
  {
    key: "MF",
    value: "Saint Martin (+590)",
  },
  {
    key: "PM",
    value: "Saint Pierre and Miquelon (+508)",
  },
  {
    key: "VC",
    value: "Saint Vincent and the Grenadines (+1-784)",
  },
  {
    key: "WS",
    value: "Samoa (+685)",
  },
  {
    key: "SM",
    value: "San Marino (+378)",
  },
  {
    key: "ST",
    value: "Sao Tome and Principe (+239)",
  },
  {
    key: "SA",
    value: "Saudi Arabia (+966)",
  },
  {
    key: "SN",
    value: "Senegal (+221)",
  },
  {
    key: "RS",
    value: "Serbia (+381)",
  },
  {
    key: "SC",
    value: "Seychelles (+248)",
  },
  {
    key: "SL",
    value: "Sierra Leone (+232)",
  },
  {
    key: "SG",
    value: "Singapore (+65)",
  },
  {
    key: "SX",
    value: "Sint Maarten (+1-721)",
  },
  {
    key: "SK",
    value: "Slovakia (+421)",
  },
  {
    key: "SI",
    value: "Slovenia (+386)",
  },
  {
    key: "SB",
    value: "Solomon Islands (+677)",
  },
  {
    key: "SO",
    value: "Somalia (+252)",
  },
  {
    key: "ZA",
    value: "South Africa (+27)",
  },
  {
    key: "KR",
    value: "South Korea (+82)",
  },
  {
    key: "SS",
    value: "South Sudan (+211)",
  },
  {
    key: "ES",
    value: "Spain (+34)",
  },
  {
    key: "LK",
    value: "Sri Lanka (+94)",
  },
  {
    key: "SD",
    value: "Sudan (+249)",
  },
  {
    key: "SR",
    value: "Suriname (+597)",
  },
  {
    key: "SJ",
    value: "Svalbard and Jan Mayen (+47)",
  },
  {
    key: "SZ",
    value: "Swaziland (+268)",
  },
  {
    key: "SE",
    value: "Sweden (+46)",
  },
  {
    key: "CH",
    value: "Switzerland (+41)",
  },
  {
    key: "SY",
    value: "Syria (+963)",
  },
  {
    key: "TW",
    value: "Taiwan (+886)",
  },
  {
    key: "TJ",
    value: "Tajikistan (+992)",
  },
  {
    key: "TZ",
    value: "Tanzania (+255)",
  },
  {
    key: "TH",
    value: "Thailand (+66)",
  },
  {
    key: "TG",
    value: "Togo (+228)",
  },
  {
    key: "TK",
    value: "Tokelau (+690)",
  },
  {
    key: "TO",
    value: "Tonga (+676)",
  },
  {
    key: "TT",
    value: "Trinidad and Tobago (+1-868)",
  },
  {
    key: "TN",
    value: "Tunisia (+216)",
  },
  {
    key: "TR",
    value: "Turkey (+90)",
  },
  {
    key: "TM",
    value: "Turkmenistan (+993)",
  },
  {
    key: "TC",
    value: "Turks and Caicos Islands (+1-649)",
  },
  {
    key: "TV",
    value: "Tuvalu (+688)",
  },
  {
    key: "VI",
    value: "U.S. Virgin Islands (+1-340)",
  },
  {
    key: "UG",
    value: "Uganda (+256)",
  },
  {
    key: "UA",
    value: "Ukraine (+380)",
  },
  {
    key: "AE",
    value: "United Arab Emirates (+971)",
  },
  {
    key: "GB",
    value: "United Kingdom (+44)",
  },
  {
    key: "US",
    value: "United States (+1)",
  },
  {
    key: "UY",
    value: "Uruguay (+598)",
  },
  {
    key: "UZ",
    value: "Uzbekistan (+998)",
  },
  {
    key: "VU",
    value: "Vanuatu (+678)",
  },
  {
    key: "VA",
    value: "Vatican (+379)",
  },
  {
    key: "VE",
    value: "Venezuela (+58)",
  },
  {
    key: "VN",
    value: "Vietnam (+84)",
  },
  {
    key: "WF",
    value: "Wallis and Futuna (+681)",
  },
  {
    key: "EH",
    value: "Western Sahara (+212)",
  },
  {
    key: "YE",
    value: "Yemen (+967)",
  },
  {
    key: "ZM",
    value: "Zambia (+260)",
  },
  {
    key: "ZW",
    value: "Zimbabwe (+263)",
  },
];
