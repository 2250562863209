import React, { Fragment } from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import NavBarLoggoutOut from "app/components/navbarLoggedOut";
import * as Yup from "yup";
import { Formik } from "formik";
import RegistrationForm from "./JwtRegisterForm";
import axios from "axios.js";
import apiConstants from "app/constants/apiPaths";
import history from "history.js";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "linear-gradient(#D26173, #DF804E)",
  },
  card: {
    width: "60%",
    borderRadius: 12,
    margin: "1rem",
  },
}));

const JwtRegister = () => {
  const classes = useStyles();
  const useV2SignupAPI = process.env.REACT_APP_USE_V2_SIGNUP === "true";

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required("First Name is required"),
    lname: Yup.string().required("Last Name is required"),
    emailid: Yup.string().email().required("Email is required"),
    mobnum: Yup.number()
      .min(10, "Mobile number should be a valid number")
      .required("Mobile Number is required"),
    isd: Yup.string().required("ISD Code is required"),
    compname: Yup.string().required("Company Name is required"),
    strength: Yup.string().required("Company strength is required"),
  });
  const initialValues = {
    emailid: "",
    fname: "",
    lname: "",
    mobnum: "",
    compname: "",
    isd: "",
    strength: "",
  };

  const onSubmit = (formValues) => {
    if (useV2SignupAPI) return onSubmitV2(formValues);

    axios
      .post(apiConstants.INSERT_TRIAL, btoa(JSON.stringify(formValues)))
      .then((response) => {
        //console.log("RESPONSE DATA NUM AP:", response);
        if (response.data === "Success") {
          sessionStorage.setItem("TRIAL_REG_EMPID", formValues.emailid);
          history.push("/session/successSignUp");
        } else if (response.data === "Exists") {
          alert("Email Id already exists. Please try another Email");
        } else if (response.data === "comp") {
          alert("Company name is already exists. Please contact Administrator");
        } else if (response.data === "Tower") {
          alert("Email id is not valid.");
        } else if (response.data === "numverify") {
          alert("Mobile number is not valid for selected country.");
        } else if (response.data === "numVerifyDown") {
          alert("Mobile number verification API down.");
        } else {
          alert("Please try Later!");
        }
      })
      .catch((err) => {
        alert('Sign up failed with error: '+err);
      });
  };

  const onSubmitV2 = (formValues) => {
    axios
      .post(apiConstants.SIGNUP_V2, JSON.stringify(formValues))
      .then((response) => {
        if (response.data.success)
          history.push("/session/successSignUpV2");
        else
          alert('Unexpected response: '+response.data.message);
      })
      .catch(({ response }) => {
        let aMsg = response.data.message;
        alert('Sign up failed with error: '+aMsg);
      });
  };

  return (
    <Fragment>
      <NavBarLoggoutOut />
      <div
        className={clsx(
          "flex justify-center items-center  min-h-full-screen",
          classes.cardHolder
        )}
      >
        <Card className={classes.card}>
          <h4 className="m-4">Try Oyster for FREE</h4>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(props) => <RegistrationForm {...props} />}
          </Formik>
          {useV2SignupAPI && <div align="right">v2&nbsp;</div>}
        </Card>
      </div>
    </Fragment>
  );
};

export default JwtRegister;
