import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavBarLoggoutOut from "app/components/navbarLoggedOut";
import clsx from "clsx";
import { Card } from "@material-ui/core";
import { Grid, Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import apiPaths from "../../../constants/apiPaths";
import axios from "axios.js";
import C1Popup from "app/c1component/C1Popup";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "linear-gradient(#D26173, #DF804E)",
  },
  card: {
    // textAlign: "center",
    width: "500px",
    padding: "20px",
    minHeight: "60%",
    height: "60%",
    borderRadius: "5px",
    border: " 1px solid #EF6F80",
    backgroundColor: " #fff",
    margin: " 0 auto",
    marginTop: "40px",
    fontSize: "16px",
  },
}));

const SuccessSignUp = () => {
  const classes = useStyles();
  const [openPopUp, setOpenPopUp] = useState(false);

  const handleResendEmail = () => {
    const email = sessionStorage.getItem("TRIAL_REG_EMPID");
    console.log(email);
    axios
      .post(apiPaths.RESEND_REG_EMAIL, btoa(JSON.stringify(email)))
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setOpenPopUp(true);
        }
      });
  };

  return (
    <>
      <NavBarLoggoutOut />
      <div
        className={clsx(
          "flex justify-center min-h-full-screen",
          classes.cardHolder
        )}
      >
        <Grid container align-content="center">
          <Card className={classes.card}>
            <Grid item xs={12}>
              <h4 className="m">Thank You !!</h4>
              <br />
              <span style={{ marginTop: "40px" }}>
                Please check your email and activate your oyster account. Check
                your spam folder as the email may go into your spam folder. If
                you still need help please email info@expressanalytics.net.
              </span>
            </Grid>
            <Grid item xs={6}>
              <Link to="/session/signin">
                <Button
                  style={{
                    float: "left",
                    marginTop: "120px",
                    width: "70px",
                    backgroundColor: "#ee6a86",
                    color: "white",
                  }}
                >
                  Sign In
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{
                  float: "right",
                  marginTop: "120px",
                  marginLeft: "100px",
                  marginBottom: "20px",
                  backgroundColor: "#ee6a86",
                  color: "white",
                }}
                onClick={handleResendEmail}
              >
                Resend Email
              </Button>
            </Grid>
          </Card>
        </Grid>
      </div>
      <C1Popup
        openPopup={openPopUp}
        setOpenPopup={setOpenPopUp}
        title="Email Resent"
      >
        <span
          style={{
            textAlign: "center",
            fontFamily: "monospace",
            fontSize: "15px",
          }}
        >
          An email has been resent to your account. Please check your email.
          Thank you!
        </span>
      </C1Popup>
    </>
  );
};

export default SuccessSignUp;
