import React from "react";
import { authRoles } from "../../auth/authRoles";

const dataExplorationReportRoutes = [
  {
    path: "/data-exploration",
    component: React.lazy(() => import("./data-exp-reports")),
    auth: authRoles.sa,
  },
  {
    path: "/query-helper",
    component: React.lazy(() => import("./query-helper")),
    auth: authRoles.sa,
  }, 

];

export default dataExplorationReportRoutes;
