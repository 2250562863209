import React from "react";
import { authRoles } from "../../auth/authRoles";

const baReportRoutes = [
  {
    path: "/business-analytics/list",
    component: React.lazy(() => import("./ba-reports")),
    auth: authRoles.sa,
  },

  // {
  //   path: "/business-analytics/d3/sankey",
  //   component: React.lazy(() =>
  //     import("../d3js_Reports/sankey/sankey-details")
  //   ),
  //   auth: authRoles.sa,
  // },

  {
    path: "/business-analytics/:viewType",
    component: React.lazy(() => import("./ba-detailed-reports")),
    auth: authRoles.sa,
  },
  {
    path: "/business/sankey_diagram",
    component: React.lazy(() => import("./sankeyDiagram")),
    auth: authRoles.sa,
  },
];

export default baReportRoutes;
