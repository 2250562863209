import JWTLogin from "./login/JwtLogin";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import JWTRegister from "./register/JwtRegister";
import ForgotPasswordUpdateDialog from "./ForgotPasswordUpdateDialog";
import SuccessSignUp from "./register/successSignUp";
import SuccessSignUpV2 from "./register/successSignUpV2";

const sessionRoutes = [
  {
    path: "/session/signup",
    component: JWTRegister,
  },
  {
    path: "/session/signin",
    component: JWTLogin,
  },
  {
    path: "/session/forgot-password/update/:resetCode+", //to fix / embedded
    component: ForgotPasswordUpdateDialog,
  },
  {
    path: "/session/forgot-password",
    component: ForgotPassword,
  },

  {
    path: "/session/404",
    component: NotFound,
  },
  {
    path: "/session/successSignUp",
    component: SuccessSignUp,
  },
  {
    path: "/session/successSignUpV2",
    component: SuccessSignUpV2,
  },
];

export default sessionRoutes;
