import React from "react";
import { authRoles } from "../../auth/authRoles";

const customerProfileRoutes = [
  {
    path: "/customerprofile/details/:masterCustomerId",
    component: React.lazy(() =>
      import("./customer-profile-details/customerProfileDetailsExpanded")
    ),
    auth: authRoles.sa,
  },
  {
    path: "/customerprofile/conciseDetails/:masterCustomerId",
    component: React.lazy(() =>
      import("./customer-profile-details/customerProfileDetails")
    ),
    auth: authRoles.sa,
  },
  {
    path: "/customerprofile",
    component: React.lazy(() =>
      import("./customer-profile-landing/customerProfileLanding")
    ),
    auth: authRoles.sa,
  },
];

export default customerProfileRoutes;
