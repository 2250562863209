import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavBarLoggoutOut from "app/components/navbarLoggedOut";
import clsx from "clsx";
import { Card } from "@material-ui/core";
import { Grid, Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import C1Popup from "app/c1component/C1Popup";

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: "linear-gradient(#D26173, #DF804E)",
  },
  card: {
    // textAlign: "center",
    width: "500px",
    padding: "20px",
    minHeight: "60%",
    height: "60%",
    borderRadius: "5px",
    border: " 1px solid #EF6F80",
    backgroundColor: " #fff",
    margin: " 0 auto",
    marginTop: "40px",
    fontSize: "16px",
  },
}));

const SuccessSignUpV2 = () => {
  const classes = useStyles();
  const [openPopUp, setOpenPopUp] = useState(false);

  return (
    <>
      <NavBarLoggoutOut />
      <div
        className={clsx(
          "flex justify-center min-h-full-screen",
          classes.cardHolder
        )}
      >
        <Grid container align-content="center">
          <Card className={classes.card}>
            <Grid item xs={12}>
              <h4 className="m"> Thank you for signing up for Trial</h4>
              <br />
              <span style={{ marginTop: "40px" }}>
               We have received your request. Soon, our Customer Success Team will contact you
                to get details on your data to be imported and will set up your Account.
                Meanwhile, if you have any questions, please write to info@expressanalytics.net.
              </span>
            </Grid>
          </Card>
        </Grid>
      </div>
      <C1Popup
        openPopup={openPopUp}
        setOpenPopup={setOpenPopUp}
        title="Email Resent"
      >
        <span
          style={{
            textAlign: "center",
            fontFamily: "monospace",
            fontSize: "15px",
          }}
        >
          An email has been resent to your account. Please check your email.
          Thank you!
        </span>
      </C1Popup>
    </>
  );
};

export default SuccessSignUpV2;
