import React, { useState, Fragment } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

import { makeStyles } from "@material-ui/core/styles";
import history from "history.js";
import clsx from "clsx";
import useAuth from "app/hooks/useAuth";
import YouTube from "react-youtube";
import NavBarLoggoutOut from "app/components/navbarLoggedOut";

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: "linear-gradient(#d26173,#df804e)",
  },
  card: {
    maxWidth: 800,
    borderRadius: 12,
    margin: "1rem",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JwtLogin = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [message, setMessage] = useState("");
  const { login } = useAuth();
  let apiname =  process.env.REACT_APP_BACK_END_URL.split(".")[0].split("/")[2];

  const classes = useStyles();

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...userInfo };
    temp[name] = value;
    setUserInfo(temp);
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      await login(userInfo.email, userInfo.password, userInfo.rememberMe);
      history.push("/");
    } catch (e) {
      setMessage(e.message);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <NavBarLoggoutOut />
      <div
        className={clsx(
          "flex justify-center items-center  min-h-full-screen",
          classes.cardHolder
        )}
      >
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="p-8 h-full relative">
                <div className="video-panel">
                  <YouTube videoId="ltDMAayOru8" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
        <Card className={classes.card}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="p-8 h-full relative">
                <ValidatorForm onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Email"
                    onChange={handleChange}
                    type="text"
                    name="email"
                    value={userInfo.email}
                    validators={["required"]}
                    errorMessages={["Email is required"]}
                  />
                  <TextValidator
                    className="mb-3 w-full"
                    label="Password"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={userInfo.password}
                    validators={["required"]}
                    errorMessages={["Password is required"]}
                  />
                  <FormControlLabel
                    className="mb-3 min-w-288"
                    name="agreement"
                    onChange={handleChange}
                    control={
                      <Checkbox
                        size="small"
                        onChange={({ target: { checked } }) =>
                          handleChange({
                            target: { name: "rememberMe", value: checked },
                          })
                        }
                        checked={userInfo.rememberMe}
                      />
                    }
                    label="Remember me"
                  />

                  {message && <p className="text-error mt-0">{message}</p>}

                  <div className="flex flex-wrap items-center mb-4">
                    <div className="relative">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        type="submit"
                      >
                        Sign in
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                    <span className="mr-2 ml-5">or</span>
                    <Button
                      className="capitalize"
                      onClick={() => history.push("/session/signup")}
                    >
                      Sign up
                    </Button>
                  </div>
                  <Button
                    className="text-primary"
                    onClick={() => history.push("/session/forgot-password")}
                  >
                    Forgot password?
                  </Button>
                </ValidatorForm>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
      * {apiname}
    </Fragment>
  );
};

export default JwtLogin;
