import React, { useState, Fragment } from "react";
import { Card, Grid, Button, CircularProgress } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import NavBarLoggoutOut from "app/components/navbarLoggedOut";

import { sendResetPasswordLink } from "../../updateForgotPasswordRoute";
import useSnackbar from "../../components/useSnackbar";

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: "linear-gradient(#d26173,#df804e)",
    minHeight: "calc(100vh - 66px)",
  },
  card: {
    maxWidth: "600px",
    borderRadius: 12,
    margin: "1rem",
  },
}));

const ForgotPassword = () => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  const [SnackBar, openSnackBar] = useSnackbar("", "success");

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  function resetPassword(email) {
    setLoading(true);
    sendResetPasswordLink(email)
      .then((response) => {
        setLoading(false);
        openSnackBar(response.data.general_message, response.data.success);
      })
      .catch((response) => {
        setLoading(false);
        openSnackBar(response.data.general_message, response.data.success);
      });
  }

  const handleFormSubmit = () => {
    dispatch(resetPassword(state));
  };

  let { email } = state;

  return (
    <Fragment>
      <NavBarLoggoutOut />
      <div
        className={clsx("flex justify-center items-center", classes.cardHolder)}
      >
        <Grid item lg={4} md={6} sm={8} xs={10}>
          <Card className={classes.card}>
            <div className="p-8 h-full bg-light-gray relative">
              <ValidatorForm
                onSubmit={handleFormSubmit}
                onError={(errors) => console.log(errors)}
              >
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  label="Email"
                  onChange={handleChange}
                  type="email"
                  name="email"
                  size="small"
                  value={email || ""}
                  validators={["required", "isEmail"]}
                  errorMessages={["Email is required", "Email is not valid"]}
                />
                <div className="flex items-center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Reset Password
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                  <span className="ml-4 mr-2">or</span>
                  <Link to="/session/signin">
                    <Button className="capitalize">Sign in</Button>
                  </Link>
                </div>
              </ValidatorForm>
            </div>
          </Card>
        </Grid>
      </div>
      <SnackBar />
    </Fragment>
  );
};

export default ForgotPassword;
