import React from "react";
import { Grid, Button, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import C1DropDownList from "app/c1component/C1DropDownList";
import C1InputField from "app/c1component/C1InputField";
import useRadioGroup from "app/components/useRadioGroup";
import isoCodes from "app/views/sessions/register/getIsoCodes";
import "./JwtRegisterForm.css";

const Form = (formik) => {
  // const {
  //   values: { firstName, lastName, email, mobileNum, companyName },
  //   errors,
  //   touched,
  //   handleSubmit,
  //   handleChange,
  //   isValid,
  //   setFieldTouched,
  // } = props;

  const handleChangeRadio = (value) => {
    formik.setFieldValue("strength", value);
  };

  // Initialize the company strength radio group
  // eslint-disable-next-line no-unused-vars
  const [_, StrengthRadioGroup] = useRadioGroup(
    "Company Strength",
    null,
    ["1-5", "6-30", "31-100", "100+"],
    handleChangeRadio,
    true
  );

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <Grid container>
        <Grid item lg={5} md={5} sm={5} xs={12}>
          <div className="p-8 justify-center items-center h-full">
            <C1InputField
              label="First Name"
              name="fname"
              margin="normal"
              placeholder="Enter you name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fname}
              className={formik.touched.fname && formik.errors.fname && "error"}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <br />
            {formik.errors.fname && formik.touched.fname && (
              <div className="input-feedback">{formik.errors.fname}</div>
            )}
            <C1InputField
              label="Email"
              name="emailid"
              required
              margin="normal"
              onChange={formik.handleChange}
              value={formik.values.emailid}
              onBlur={formik.handleBlur}
              InputLabelProps={{
                shrink: true,
              }}
              className={
                formik.touched.emailid && formik.errors.emailid && "error"
              }
            />
            <br />
            <br />
            {formik.errors.emailid && formik.touched.emailid && (
              <div className="input-feedback">{formik.errors.emailid}</div>
            )}
            <C1InputField
              label="Company Name"
              name="compname"
              margin="normal"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.compname}
              InputLabelProps={{
                shrink: true,
              }}
              className={
                formik.touched.compname && formik.errors.compname && "error"
              }
            />
            <br />
            <br />
            {formik.errors.compname && formik.touched.compname && (
              <div className="input-feedback">{formik.errors.compname}</div>
            )}
          </div>
        </Grid>
        <Grid item lg={7} md={7} sm={7} xs={12}>
          <div className="p-8 h-full">
            <C1InputField
              label="Last Name"
              name="lname"
              required
              margin="normal"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lname}
              InputLabelProps={{
                shrink: true,
              }}
              className={formik.touched.lname && formik.errors.lname && "error"}
            />
            <br />
            <br />
            {formik.errors.lname && formik.touched.lname && (
              <div className="input-feedback">{formik.errors.lname}</div>
            )}
            <Box display="flex">
              <Grid>
                <C1DropDownList
                  label="ISD Code"
                  name="isd"
                  value={formik.values.isd}
                  onChange={(e) => {
                    formik.setFieldValue("isd", e);
                  }}
                  onBlur={formik.handleBlur}
                  viewType="edit"
                  dropDownList={isoCodes.map((country) => {
                    return { value: country.key, description: country.value };
                  })}
                  required
                />
                <br />

                {formik.errors.isd && formik.touched.isd && (
                  <div className="input-feedback">{formik.errors.isd}</div>
                )}
              </Grid>
              <C1InputField
                label="Mobile Number"
                name="mobnum"
                required
                margin="normal"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobnum}
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                className={
                  formik.touched.mobnum && formik.errors.mobnum && "error"
                }
              />
            </Box>
            <br />
            {formik.errors.mobnum && formik.touched.mobnum && (
              <div className="input-feedback">{formik.errors.mobnum}</div>
            )}
            {/* Company Strength use custom Hook */}
            <StrengthRadioGroup />{" "}
            <div className="flex items-center">
              <Button
                className="capitalize"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!(formik.isValid && formik.dirty)}
              >
                Sign up
              </Button>

              <span className="mx-2 ml-5">or</span>
              <Link to="/session/signin">
                <Button variant="contained" color="primary">
                  Sign in
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
