import { authRoles } from "./auth/authRoles";

export const navigations = [
  // {
  //   name: "dashboard",
  //   path: "/dashboard/analytics-2",
  //   icon: "dashboard",
  // },
  // {
  //   name: "Executive Dashboard",
  //   path: "/executive-dashboard",
  //   icon: "dashboard",
  // },
  // {
  //   name: "Segmentation",
  //   path: "/segmentation",
  //   icon: "dashboard",
  // },
  //Applications
  // {
  //   name: "navigations.applications.name",
  //   icon: "description",
  //   children: [
  //     {
  //       name: "navigations.applications.children.co",
  //       path: "/application/co/list",
  //       iconText: "CC",
  //     },
  //   ],
  // },
  //Administrations
  // {
  //   name: "administration",
  //   icon: "supervisor_account_icon",
  //   path: "/administration/",
  // },
  //Collection
  // {
  //   name: "collection",
  //   icon: "collections",
  //   path: "/collection/",
  // },
  // For Reference
  // {
  //   label: "configurations",
  //   type: "label",
  // },
  // {
  //   name: "masterTables",
  //   icon: "table_view",
  //   children: [
  //     {
  //       name: "ctryCodes",
  //       path: "/master/country/list",
  //       iconText: "CC",
  //     },
  //   ],
  // },
];
