import React from "react";
import { authRoles } from "../../auth/authRoles";

const vocaRoutes = [
  {
    path: "/voca",
    component: React.lazy(() => import("./Dashboard")),
    auth: authRoles.sa,
  },
  {
    path: "/SetKPI",
    component: React.lazy(() => import("./SetKPI")),
    auth: authRoles.sa,
  },
  
  {
    path: "/SentimentRatings",
    component: React.lazy(() => import("./SentimentRatings")),
    auth: authRoles.sa,
  },
  
  {
    path: "/RadarChart",
    component: React.lazy(() => import("./RadarChart")),
    auth: authRoles.sa,
  },
  
  {
    path: "/EmotionRadarGraph",
    component: React.lazy(() => import("./EmotionRadarGraph")),
    auth: authRoles.sa,
  },

  {
    path: "/EmotionLinePlot",
    component: React.lazy(() => import("./EmotionLinePlot")),
    auth: authRoles.sa,
  },];

export default vocaRoutes;
