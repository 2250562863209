import "../index.css";
import "../fake-db";
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Provider } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";
import { DndProvider } from "react-dnd"; //see:
import { HTML5Backend } from "react-dnd-html5-backend"; //see:

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { GlobalCss, MatxSuspense } from "matx";
import sessionRoutes from "./views/sessions/SessionRoutes";
import landingRoutes from "./views/landing/LandingRoutes";
import { AuthProvider } from "app/contexts/JWTAuthContext";
import MatxLayout from "./MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";
import C1GlobalCss from "app/c1component/C1GlobalCss";
import "i18n";

const App = () => {
  return (
    <AppContext.Provider value={{ routes }}>
      <DndProvider backend={HTML5Backend}>
        <Provider store={Store}>
          <MatxTheme>
            <GlobalCss>
              <C1GlobalCss>
                <Router history={history}>
                  <AuthProvider>
                    <MatxSuspense>
                      <Switch>
                        {/* AUTHENTICATION PAGES */}
                        {sessionRoutes.map((item, ind) => (
                          <Route
                            key={ind}
                            path={item.path}
                            component={item.component}
                          />
                        ))}

                        {/* LANDING PAGES */}
                        {landingRoutes.map((item, ind) => (
                          <Route
                            key={ind}
                            path={item.path}
                            component={item.component}
                          />
                        ))}

                        {/* AUTH PROTECTED PAGES */}
                        <AuthGuard>
                          <MatxLayout />
                        </AuthGuard>
                      </Switch>
                    </MatxSuspense>
                  </AuthProvider>
                </Router>
              </C1GlobalCss>
            </GlobalCss>
          </MatxTheme>
        </Provider>
      </DndProvider>
    </AppContext.Provider>
  );
};

export default App;
