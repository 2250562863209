import React from "react";

const configurationRoutes = [
  {
    path: "/master/country/list",
    component: React.lazy(() => import("./masters/country/CountryList")),
  },
  {
    path: "/master/country/:viewType/:ctryCode",
    component: React.lazy(() => import("./masters/country/CountryFormDetails")),
  },
];

export default configurationRoutes;
