import React from "react";
import { authRoles } from "../../auth/authRoles";

const supersetRoutes = [
  {
    path: "/dashboard-superset/:name",
    component: React.lazy(() => import("./DashboardSuperset")),
    auth: authRoles.sa,
  },
];

export default supersetRoutes;
