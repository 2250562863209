import Mock from "../mock";

const coDB = {
  cos: [
    {
      coDocno: "10013456312856504",
      TCoMstCoType: {
        id: {
          typeId: "SICC_CO",
          typeAccnid: "SICC",
        },
        typeDesc: "SICC Non  Preferential CO",
      },
      TCoreAccnByCoApproverAccnid: {
        accnId: "SICC",
        accnName: "SINGAPORE INTERNATIONAL CHAMBER OF COMMERCE",
      },
      TCoreAccnByCoOwnerAccnid: {
        accnId: "UEXP1",
        accnName: "UAT COMPANY",
      },
      coAppnNo: "",
      coCreatedDt: new Date("2018-11-20 09:36:06"),
      coCreatedBy: "UEXP10000",
      coUpdatedDt: new Date("2018-11-20 09:36:06"),
      coUpdatedBy: null,
      coAppliedDt: new Date("2018-11-20 09:36:06"),
      coAppliedBy: "UAT ADMIN BATCH 1",
      coAppliedLocCd: null,
      coAppliedLocName: "IT",
      coDeclinedDt: null,
      coDeclinedBy: null,
      coSubmitDt: new Date("2018-11-20 09:36:06"),
      coSubmitBy: "UEXP10000",
      coVerifiedDt: null,
      coVerifiedBy: null,
      coProcessedDt: null,
      coProcessedBy: null,
      coApprovedDt: null,
      coApprovedBy: null,
      coRejectedDt: null,
      coRejectedBy: null,
      coCancelledDt: null,
      coCancelledBy: null,
      coModifiedDt: null,
      coModifiedBy: null,
      coRefNo: null,
      coTypeCd: "864",
      coName: "E",
      coRemark: "REMARKSREMARKS",
      coSndrId: "UEXP10000",
      coRcptId: "SICC",
      coIssueCtryCd: "SG",
      coGdsDepartDt: null,
      coConsignId: "20181120145406000167",
      coConsignName: "TRIAL20181120",
      coConsignAddr1: "TAISENG",
      coConsignAddr2: null,
      coConsignAddr3: null,
      coConsignAddr4: null,
      coConsignStreetName: null,
      coExptrId: null,
      coExptrName: "UAT COMPANY",
      coExptrAddr1: "ABC TAI SENG",
      coExptrAddr2: "TESTING OF ADDRESS",
      coExptrAddr3: null,
      coExptrAddr4: "SINGAPORE 123456",
      coExptrStreetName: null,
      //stores the signature id
      coExhibitrId: "EXP_SIG2.JPG",
      coTransName: "DIRECT",
      coPortOfDischargeCd: "AFBIN",
      //only populate if the port code is zzz
      coPortOfDischargeName: "",
      coOriginCtryCd: "AF",
      coOriginCtryDesc: "AFGHANISTAN",
      coFinaldestCtryCd: "AF",
      coFinaldestCtryName: null,
      coGoodsOption: null,
      coGoodsOptOrigin: null,
      coGoodsOptOriginCert: null,
      coGoodsOptOriginCertno: null,
      coGoodsOptVesselFlag: null,
      coGoodsOptOthers: null,
      coGoodsGrossWeight: null,
      coGoodsNetWeight: null,
      coStatus: "E",
      coPortOfDischargeType: null,
      coDepartureType: "",
      coDepartureDt: new Date("2018-11-17 00:00:00"),
      coVslFlt: "AMERICANAIR",
      coConsignCtry: null,
      coConsignCtryName: null,
      coPrintMedia: "A4",
      coPrintTarget: "SELF",
      coHighPriority: "0",
      coPrintRemarks: null,
      coNoOfOrig: "1",
      coNoOfCopies: "3",
      coNoOfChamberCopies: "1",
      coGoodsDeclType: "L",
      //this are bytestream or can be replaced with file path?
      coTCoyStamp: [],
      coTSignatory: [],
      coCCoyStamp: [],
      coCSignatory: [],
      coIccLogo: [],
      coBlockchainAddr: "10017877980329178",
      coRejectRemarks: null,
      coResubmitDifference: null,
      coReqEmbassyEndrsment: "1",
      coPrintedBy: null,
      coPrintedCopies: "0",
    },

    //Add moree records here
  ],
};

Mock.onGet("/api/co/all").reply((config) => {
  return [200, coDB.cos];
});

Mock.onGet("/api/co", { params: { searchDocNo: "10013456312856504" } }).reply(
  (config) => {
    const coDocno = "10013456312856504";
    const response = coDB.cos.find((co) => co.coDocno === coDocno);
    return [200, response];
  }
);

Mock.onPost("/api/co/delete").reply((config) => {
  let co = JSON.parse(config.data);
  let index = { i: 0 };
  coDB.cos.forEach((element) => {
    if (element.coDocno === co.coDocno) {
      return [200, coDB.cos.splice(index.i, 1)];
    }
    index.i++;
  });
  return [200, coDB.cos];
});

Mock.onPost("/api/co/update").reply((config) => {
  let co = JSON.parse(config.data);
  let index = { i: 0 };
  coDB.cos.forEach((element) => {
    if (element.coDocno === co.coDocno) {
      coDB.cos[index.i] = co;
      return [200, coDB.cos];
    }
    index.i++;
  });
  return [200, coDB.cos];
});

Mock.onPost("/api/co/add").reply((config) => {
  let co = JSON.parse(config.data);
  coDB.cos.push(co);
  return [200, coDB.cos];
});
