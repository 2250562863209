import Mock from "../mock";

const countryList = [
  { ctyCode: "SG", ctyDescription: "SINGAPORE" },
  { ctyCode: "KH", ctyDescription: "CAMBODIA" },
  { ctyCode: "MY", ctyDescription: "MALAYSIA" },
];

const portList = [
  {
    portCode: "SGAYC",
    TMstCountry: { ctyCode: "SG" },
    portDescription: "PULAN AYER CHAWAN",
  },
  {
    portCode: "SGJUR",
    TMstCountry: { ctyCode: "SG" },
    portDescription: "JURONG",
  },
  {
    portCode: "SGKEP",
    TMstCountry: { ctyCode: "SG" },
    portDescription: "KEPPEL WHARVES",
  },
  {
    portCode: "KHCHE",
    TMstCountry: { ctyCode: "KH" },
    portDescription: "CHEKO",
  },
  {
    portCode: "KHKKO",
    TMstCountry: { ctyCode: "KH" },
    portDescription: "KAS KONG",
  },
  {
    portCode: "KHKOS",
    TMstCountry: { ctyCode: "KH" },
    portDescription: "KAMPONG SAOM",
  },
  {
    portCode: "MYAOR",
    TMstCountry: { ctyCode: "MY" },
    portDescription: "ALOR SETAR",
  },
  {
    portCode: "MYBAT",
    TMstCountry: { ctyCode: "MY" },
    portDescription: "BATU PAHAT",
  },
  {
    portCode: "MYBBN",
    TMstCountry: { ctyCode: "MY" },
    portDescription: "BARIO",
  },
];

Mock.onGet("/api/ctry/all").reply((config) => {
  return [200, countryList];
});

Mock.onGet("/api/ports/all").reply((config) => {
  return [200, portList];
});

Mock.onGet("/api/ctry/SG/ports").reply((config) => {
  const ctyCode = "SG";
  const response = portList.cos.find(
    (port) => port.TMstCountry.ctyCode === ctyCode
  );
  return [200, response];
});

Mock.onGet("/api/ctry/KH/ports").reply((config) => {
  const ctyCode = "KH";
  const response = portList.cos.find(
    (port) => port.TMstCountry.ctyCode === ctyCode
  );
  return [200, response];
});

Mock.onGet("/api/ctry/MY/ports").reply((config) => {
  const ctyCode = "MY";
  const response = portList.cos.find(
    (port) => port.TMstCountry.ctyCode === ctyCode
  );
  return [200, response];
});
