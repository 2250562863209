import Mock from "../mock";

const auditDb = {
  audits: [
    {
      audtId: "10013456312856504",
      audtEvent: "Event",
      audtTimestamp: new Date("2018-11-20 09:36:06"),
      audtRemarks: "UEXP10000",
      audtUid: "UEXP10000",
      audtUname: "UEXP10000",
      audtReckey: "10013456312856504",
    },

    //Add moree records here
  ],
};

Mock.onGet("/api/audit/all").reply((config) => {
  return [200, auditDb.audits];
});

Mock.onGet("/api/audit", {
  params: { searchAudtId: "10013456312856504" },
}).reply((config) => {
  const audtId = "10013456312856504";
  const response = auditDb.audits.find((audt) => audt.audtId === audtId);
  return [200, response];
});
