import React from "react";
import { authRoles } from "../../auth/authRoles";

const paReportRoutes = [
  {
    path: "/predictive-analytics/list",
    component: React.lazy(() => import("./pa-reports")),
    auth: authRoles.sa,
  },

  {
    path: "/predictive-analytics/:viewType",
    component: React.lazy(() => import("./pa-detailed-reports")),
    auth: authRoles.sa,
  },
];

export default paReportRoutes;
