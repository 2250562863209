import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Card,
  IconButton,
} from "@material-ui/core";

import { updateForgotPassword } from "../../updateForgotPasswordRoute";
import useSnackbar from "../../components/useSnackbar";
import NavBarLoggoutOut from "app/components/navbarLoggedOut";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: "linear-gradient(#d26173,#df804e)",
    minHeight: "calc(100vh - 66px)",
  },
  card: {
    maxWidth: "600px",
    borderRadius: 12,
    margin: "1rem",
  },
}));

const ForgotPasswordUpdateDialog = ({ match }) => {
  const classes = useStyles();
  const requestBody = {};
  requestBody["data"] = match.params.resetCode;

  const [passwordOpen, setPasswordOpen] = React.useState(true);
  const [SnackBar, openSnackBar] = useSnackbar("", "success");
  let [newPassword, setNewPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");

  const handleClose = () => {
    setPasswordOpen(false);
  };

  const [values, setValues] = React.useState("");
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleSubmit = () => {
    requestBody["pass"] = newPassword;
    if (newPassword === "" || confirmPassword === "") {
      openSnackBar("Password fields can't be empty.", "error");
      return;
    }
    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        newPassword
      )
    ) {
      openSnackBar("Entered password does not match the guidelines.", "error");
      return;
    }
    if (newPassword === confirmPassword) {
      updateForgotPassword(requestBody)
        .then((response) => {
          if (response.data.success === "true") {
            openSnackBar(response.data.general_message, "success");
            setTimeout(() => {
              window.location.replace("/session/signin");
            }, 3000);
          } else {
            openSnackBar(response.data.general_message, "error");
          }
        })
        .catch(() => {
          openSnackBar("Password update failed!", "error");
        });
    } else {
      openSnackBar("The confirm password did not match, try again!", "error");
    }
  };
  return (
    <React.Fragment>
      <NavBarLoggoutOut />
      <div
        className={clsx("flex justify-center items-center", classes.cardHolder)}
      >
        <Grid item lg={4} md={6} sm={8} xs={10}>
          <Card className={classes.card}>
            <Dialog
              open={passwordOpen}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Create New Password
              </DialogTitle>

              <form>
                <DialogContent>
                  <DialogContentText style={{ color: "#000000" }}>
                    Please enter the new password to update the record:
                  </DialogContentText>
                  <DialogContentText style={{ color: "#DA291C" }}>
                    (Guidelines: Password must contain eight characters, at
                    least one uppercase letter, one lowercase letter, one number
                    and one special character.)
                  </DialogContentText>

                  <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(e) => setNewPassword(e.target.value)}
                      //onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            //onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Current Password"
                    />
                  </FormControl>
                  <br></br>
                  <h6> </h6>
                  <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      //onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            //onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New Password"
                    />
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>
                  <Button onClick={handleSubmit}>Update</Button>
                </DialogActions>
              </form>
            </Dialog>

            <SnackBar />
          </Card>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default ForgotPasswordUpdateDialog;
